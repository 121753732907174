import { CustomerReviewProps } from 'interfaces/cms/content';
import { Body1 } from 'components/common/Body';
import QuotationMarksFront from '@public/icons/feedback1.svg';
import QuotationMarksEnd from '@public/icons/feedback2.svg';

function CustomerReview({
  customerName,
  customerInfo,
  review,
  rating = 0,
}: CustomerReviewProps) {
  const StarRating = (props: any) => {
    const { starRating, count } = props;
    const percentage = (starRating / count) * 100;
    return (
      <div
        className="relative text-[20px] text-transparent bg-clip-text"
        style={{
          background: `linear-gradient(90deg, #f78f1e ${percentage}%, #ced3e4 ${percentage}%)`,
        }}
      >
        ★ ★ ★ ★ ★
      </div>
    );
  };
  return (
    <div className="bg-white w-[100%] min-h-[100%] p-[1.875rem] text-black rounded-[0.9375rem] flex flex-col items-center justify-start text-center shadow-[0px_0.625rem_0.625rem_0px_#1066cb1a]">
      <div className="h-[1.5625rem] overflow-hidden text-black">
        <Body1 isBold textColor="black" style={{ fontSize: '1.125rem' }}>
          {customerName}
        </Body1>
      </div>
      <div className="h-[2.1875rem] pt-[0.625rem] overflow-hidden whitespace-normal">
        <Body1 textColor="text-[#4e4b64]">{customerInfo}</Body1>
      </div>
      <hr className="pt-[20px] border-0 border-b border-dashed border-[#d8dbe9] w-[100px]" />
      <div className="h-[10rem] pt-[1.25rem] overflow-hidden whitespace-normal">
        <Body1 textColor="text-[#4e4b64]">
          <QuotationMarksFront
            style={{ display: 'inline-block', marginRight: '0.625rem' }}
          />
          {review}
          <QuotationMarksEnd
            style={{ display: 'inline-block', marginLeft: '0.625rem' }}
          />
        </Body1>
      </div>
      <div className="h-[50px] overflow-hidden text-ellipsis">
        <p className="text-[18px] font-bold pt-5 pb-[5px]">{rating}</p>
      </div>
      <StarRating starRating={rating} count={5} />
    </div>
  );
}

export default CustomerReview;
